import './App.css';
import React, { useEffect, useState } from 'react';
// MSAL imports
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { i18n, MainLayout } from 'quil';
import { I18nextProvider } from 'react-i18next';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import CustomNavigationClient from './utils/NavigationClient';
import { loginRequest } from './authConfig';
import Dashboard from './pages/Dashboard';
import Assays from './pages/Assays';
import AssayEditor from './pages/AssayEditor';
import Experiments from './pages/Experiments';
import ExperimentEditor from './pages/ExperimentEditor';
import Login from './pages/Login';
import Settings from './pages/Settings';

function Layout() {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [outlet, setOutlet] = useState(location.pathname);
  // const [navOpen, setNavOpen] = useState(false);
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const activeUser = activeAccount && activeAccount.name;

  useEffect(() => {
    // Update the app bar based on the current route
    const currentPath = location.pathname;
    setOutlet(currentPath);
  }, [location]);

  // const handleToggleNav = () => {
  //   setNavOpen((prevState) => !prevState);
  // };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  // const handleLogout = () => {
  //   instance.logoutRedirect().catch((error) => console.log(error));
  // };

  // const handleLoginSso = () => {
  //   handleLogin;
  // };

  const navigate = useNavigate();

  const handleNavigate = (navTo: string) => {
    navigate(navTo);
  };

  const isTestEnv: boolean = import.meta.env.VITE_REACT_APP_PLAYWRIGHT;

  return isTestEnv ? (
    <MainLayout
      content={<Outlet />}
      onNavTo={handleNavigate}
      activeUser={activeUser || ''}
    />
  ) : (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <MainLayout
            content={<Outlet />}
            onNavTo={handleNavigate}
            activeUser={activeUser || ''}
          />
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login onLoginSso={handleLogin} />
      </UnauthenticatedTemplate>
    </>
  );
}

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="assays" element={<Assays />} />
            <Route path="assays/editor" element={<AssayEditor />} />
            <Route path="experiments" element={<Experiments />} />
            <Route path="experiments/editor" element={<ExperimentEditor />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </I18nextProvider>
    </MsalProvider>
  );
}

export default App;
